

/* ---------------------- fonts----------------*/



@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Poppins&display=swap');

.pargraph{
    font-family: 'Poppins';
}



@font-face {
    font-family: pixel;
    src: url('../assets/fonts/minecraft/Minecraft.ttf');
  }


  /* .wrapper{
    width: 300px;
    margin: 0 auto;    
} */


.background{
    background-image: url('../images/backgroundpic.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}


.carousel.carousel-slider .control-arrow{
    padding: none;
    color:red;
    margin: 0%;
    font-size: 0%;
    
}

.carousel img {
    width: 0%; 
    display: inline-block;
    pointer-events: none;
}

.carousel .slide img {
    width: 55%;
    height: 25%;
    vertical-align: top;
    border: 0;
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
.desktopView{
    display: none;
}

.TabletArtifactLibrary {
    display: none;
}
.NFTtabletView{
 display: none;
}
.CarosuelBAckground {
    display: none;
}

.RacoveryPunksDot{
   display: none;
}

.NFTtradingGame{
    display: none;
}
.CancelBtn {
    display: none;
}
.attributes{
    display: none;
}

.RoadmapScetion{
    display: none;
}

.fotter {
    display: none;
}
.carousel .slide img {
    width: 70%;
  
    vertical-align: top;
    border: 0;
}

.Atrifact {
    display: none;
       }
   
    .NFTtradingGame{
       display: none;
    }
   
    .FAQ{
       display: none;
    }

}


/* tab screen devices (600px and above) */
@media only screen and (max-width: 768px) {
    .Atrifact {
 display: none;
    }

 .NFTtradingGame{
    display: none;
 }

 .FAQ{
    display: none;
 }
 .fotter {
    display: none;
 }
    
}